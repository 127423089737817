<template lang="pug">
div.select-options
  carousel(:scrollPerPage="true"
    :perPage="4"
    :navigationEnabled="true"
    :paginationEnabled="false"
    ref="carousel"
    :navigation-next-label="''"
    :navigation-prev-label="''"
    v-if="accessories.length > 4")
    slide.select-options-item(v-for="(accessorItem, index) in accessories" :key="index")
      div.check-label.check-errors(
        v-if="errors"
        :class="{'is-invalid':errors.has('orderAccessories')}"
      )
        .check-text
          div.check-text-block
            div.check-text-title {{ accessorItem.name }}
            div.check-text-description {{ accessorItem.description }}

        label(:for="`accessor${accessorItem.id}`").check-checkbox-wrapper
          .check-input
            input.custom-check-input(
              type="checkbox"
              v-model="selectedAccessories"
              :value="accessorItem.id"
              :checked="!accessorItem.opt_in"
              :disabled="!accessorItem.opt_in"
              :id="`accessor${accessorItem.id}`"
              @change="showPopup($event)"
            )
            .check.custom-check
              Check
            span.price-text +&nbsp;{{ formatPrice( calculateAccessoryMonthlyCost(accessorItem)) }}&nbsp;{{ currencySymbol }}/{{ $t('mon') }}

  div(v-else).options-nonslider.d-flex
    div.select-options-item(v-for="(accessorItem, index) in accessories" :key="index")
      div.check-label.check-errors(
        v-if="errors"
        :class="{'is-invalid':errors.has('orderAccessories')}"
      )
        .check-text
          div.check-text-block
            div.check-text-title {{ accessorItem.name }}
            div.check-text-description {{ accessorItem.description }}

        label(:for="`accessor${accessorItem.id}`").check-checkbox-wrapper
          .check-input
            input.custom-check-input(
              type="checkbox"
              v-model="selectedAccessories"
              :value="accessorItem.id"
              :checked="!accessorItem.opt_in"
              :disabled="!accessorItem.opt_in"
              :id="`accessor${accessorItem.id}`"
              @change="showPopup($event)"
            )
            .check.custom-check
              Check
            span.price-text +&nbsp;{{ formatPrice( calculateAccessoryMonthlyCost(accessorItem)) }}&nbsp;{{ currencySymbol }}/{{ $t('mon') }}
</template>

<script>
import {mapActions, mapState} from "vuex";
import { formatPrice, } from '@/helpers/helpers.js';
import { Carousel, Slide } from 'vue-carousel';
export default {
  name: "SelectAccessories",
  components: {
    Check: () => import('@/components/svg/check.vue'),
    Carousel,
    Slide
  },
  data:() => ({
    isPopup: false,
    selected: []
  }),
  props: {
    accessories: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    ...mapActions('product', ['setSelectedAccessories']),
    formatPrice,
    showPopup(e) {
      if (this.isDownPayment && e.target.checked) {
        this.$set(this.$data, 'isPopup', true);
      }
    },
    closePopup() {
      this.$set(this.$data, 'isPopup', false);
    },
    calculateAccessoryMonthlyCost(accessory) {
      if (!accessory) return 0;

      if (accessory.based_on === 'Totalbelopp') {
        const priceForm = this.$store.state.product.priceForm;
        const cashPaymentPercentage = this.$store.getters['financing/cashPaymentPercentage'];
        const calculateMonthlyCost = this.$store.getters['financing/calculateMonthlyCost'];

        const accessoryMonthlyCost = priceForm === 'Down payment'
          ? calculateMonthlyCost(accessory.total_price * (1 - cashPaymentPercentage / 100), 0)
          : calculateMonthlyCost(accessory.total_price, accessory.total_price * accessory.residual / 100);

        return Math.round(accessoryMonthlyCost);
      }

      if (accessory.based_on === 'Månadspris' || accessory.based_on === 'Månedlig pris') {
        return accessory.monthly_price;
      }

      return 0;
    },
  },
  computed: {
    ...mapState('reseller', {
      accessoryStyle: state => state.customStyle.accessoryPage || {},
      accessoryPageButton: state => state.customStyle.accessoryPage?.submit_btn_text || '',
      currencySymbol: state => state.resellerInfo?.market?.currency?.symbol || 'kr',
    }),
    selectedAccessories: {
      get() {
        return this.$store.state.product.selectedAccessories
      },
      set(value) {
        this.$store.commit('product/setSelectedAccessories', value)
      }
    },
  },
  watch: {
    selectedAccessories: function (n) {
      this.$store.commit('order/setAccessories', n)
    },
  }
}
</script>

<style scoped lang="sass">
.VueCarousel-slide
  visibility: visible
.options-nonslider
  .select-options-item:nth-child(1)
    border-left: 1px solid rgba(58, 57, 57, 0.4)
</style>